import React, { Component, Fragment } from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { TrackingInfo } from './detailComponents/TrackingInfo/TrackingInfo';
import axios from '../../utils/axios';
import { API_URL, offerTypesConfig, SubmissionStatuses } from '../../constants';
import BikeInfo from './detailComponents/bikeInfo';
import GearInfo from './detailComponents/gearInfo';
import OfferBuyerInfo from './detailComponents/OfferBuyerInfo';
import Modal from '../../components/modals/genericFormModal';
import Loader from '../../components/loader';
import SubmissionHistoryTable from './detailComponents/SubmissionHistoryTable';
import ItemHistoryTable from './detailComponents/ItemHistoryTable';
import ImageHistoryTable from './detailComponents/ImageHistoryTable';
import PreviousSubmissionsTable from './detailComponents/PreviousSubmissionsTable';
import { styleStatus, createYearsArray, getAcceptedOffer } from './helper';
import cpoShield from '../../images/cpo-shield.svg';
import { FormatMoney, formatPhone } from '../../utils/helper';
import SellerInfoForm from './SellerInfoForm';
import * as services from './sybService';
import {
  Submission,
  SubmissionReceivedTrackingNumber,
  Buyer,
  Status,
  StatusName,
  Discipline,
  DeclineReason,
  ReactiveSettings,
  CASH_BASED_OFFER_TYPES,
  SubmissionOfferTypes,
} from '../../typeORM';
// styles
import styles from '../../styledComponents';
const { StripedDataGrid } = styles;

import './submissionDetail.scss';
import 'react-notifications/lib/notifications.css'; // NotificationManager called in sybService
import { Alert, AlertTitle } from '@material-ui/lab';
import { SubmissionStatusIds } from '../../constants';
import { ConfirmAddressModal } from './ConfirmAddressModal';
import { cycleTimingsContext } from '../service/cycleTimings/cycleTimingsContext';
import { StickyHeader } from '../../components/library/StickyHeader/StickyHeader';
import { SubmissionChip } from './SubmissionChip';
import { ReactComponent as Bike } from '../../images/bike.svg';
import { ReactComponent as Frame } from '../../images/frame.svg';
import { ReactComponent as Wheel } from '../../images/wheel.svg';
import { purple, cyan } from '@material-ui/core/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InternalInfoForm from './InternalInfoForm';
import LabelValuePairDisplay from './detailComponents/LabelValuePairDisplay';
import { TRADEUP_SEARCH_ROUTE } from './SubmissionListView';
import LookerModelInfo from './detailComponents/LookerModelInfo';
import { PageWrapper } from '../../styledComponents/wrappers';
import { BoxWithLabel } from '../../components/library';
import { connect } from 'react-redux';

const { formatStatuses, fetchPreviousPartnerSubmissions, fetchSubmissionReceivers } = services;

export const SUBMISSION_DETAIL_ROUTE = '/tradeups/submission';
export const VERIFF_STATION_VERIFICATIONS_URL = 'https://station.veriff.com/verifications';

/* MASTER TODO LIST:
- chunk this out into components per concern (e.g. InternalInfo like OfferBuyerInfo = display component + modal) + break up state
 - any cross-cutting state concerns should be moved to a Redux store
- update FE/BE contract to return unprocessed data types without "convenience values" (SubmissionService/GetSubmission does a lot of munging)
 so that this can use entity types without extra fields
- import class types from TypeORM entities and type more aggressively based on these types
 - remove the typeORM folder / stubs
- refactor non-standard DOM (labels for <p> tags)
- replace item image rotation DOM/state with ExpandRotateImage
- move lookups (e.g. Buyers, DeclineReasons) into Redux store so they aren't passed around

Take on the TODO list during the refactor of the page for the 'parts identifier' project.
*/

interface SubmissionDetailViewProps extends RouteComponentProps<{ submissionId: string }> {
  user: any;
}

export interface SubmissionDetailViewState {
  initialLoad: boolean;
  loading: boolean;
  noSubmissionFound: boolean;
  editPermissions: boolean;
  displayModal: boolean;
  displayUpdateModal: boolean;
  displayRejectModal: boolean;
  rotate: number;
  modalImage: string;
  submission: Submission | null;
  reactiveSettings: ReactiveSettings | null;
  failedSubmission: any; // todo: any -> TypeORM type
  submissionHistory?: any[]; // todo: any -> TypeORM type
  itemHistory: any[]; // todo: any -> TypeORM type
  imageHistory: any[]; // todo: any -> TypeORM type
  submissionPartnerPrevious: any[]; // todo: any -> TypeORM type
  submissionReceivers: string[];
  receivedTrackingNumbers: {
    trackingNumber: string;
    linkUrl: string;
  }[];
  currentValue: {
    amount: number;
    month: number;
    soldDate?: string;
  };
  // edit modal values
  buyers: Buyer[];
  manualEntry: false;
  declineReasons: DeclineReason[];
  declineReasonId: number | null;
  externalNote: string;
  internalNote: string;
  rejectNote: string;
  statuses: Status[];
  statusId: number | null;
  expectedValue: number | null;
  editSuccess: boolean;
  editError: boolean;
  // #region Internal bike values
  internalYear: number | null; // todo: move to InternalInfo component
  internalSize: string | null; // todo: move to InternalInfo component
  internalWheelSize: string | null; // todo: move to InternalInfo component
  internalBuild: string | null; // todo: move to InternalInfo component
  internalBrand: string | null; // todo: move to InternalInfo component
  internalModel: string | null; // todo: move to InternalInfo component
  internalPipeline: string | null; // todo: move to InternalInfo component
  internalDiscipline: string | null; // todo: move to InternalInfo component
  internalCategory: string | null; // todo: move to InternalInfo component
  internalMsrp: number | null;
  years: number[];
  categories: any[]; // todo: any
  brands: any[]; // todo: any
  pipelines: any[]; // todo: any
  disciplines: Discipline[];
  wheelTier: string;
  buildTier: string;
  powerMeter: boolean | null;
  frameMaterial: string;
  internalEditSuccess: boolean;
  internalEditError: boolean;
  // #endregion
  idImg: { signedRequest?: string };
  externalSubmission: boolean;
  partnerLogoUrl: string;
  // Item catalog product id if submission associated to product variant via item_procurements table
  catalogProductId?: number;
  verificationIds: string[] | undefined;
  lookerModelReportBaseUrl?: string;
  expandDefaultStatusIds?: number[];
}

interface FetchDataResult {
  submission: Submission;
  reactiveSettings: ReactiveSettings;
  submissionPrevious: {
    id: number;
    status: StatusName;
    created: string;
    dealerName: string;
    item: string;
  }[];
  catalogProductId?: number;
}

class SubmissionDetailView extends Component<SubmissionDetailViewProps, SubmissionDetailViewState> {
  constructor(props: SubmissionDetailViewProps) {
    super(props);
    this.state = {
      initialLoad: false,
      loading: false,
      noSubmissionFound: false,
      editPermissions: true,
      displayModal: false,
      displayUpdateModal: false,
      displayRejectModal: false,
      rotate: 0,
      modalImage: '',
      submission: null,
      reactiveSettings: null,
      failedSubmission: {},
      submissionHistory: [],
      itemHistory: [],
      imageHistory: [],
      submissionPartnerPrevious: [],
      submissionReceivers: [],
      receivedTrackingNumbers: [],
      currentValue: {
        amount: 0,
        month: 0,
      },
      // edit modal values
      buyers: [],
      manualEntry: false,
      declineReasons: [],
      declineReasonId: null,
      externalNote: '',
      internalNote: '',
      rejectNote: '',
      statuses: [],
      statusId: null,
      expectedValue: null,
      editSuccess: false,
      editError: false,
      // #region Internal bike values
      internalYear: null,
      years: createYearsArray(1970),
      categories: [],
      brands: [],
      internalModel: '',
      internalBrand: '',
      pipelines: [],
      internalPipeline: '',
      disciplines: [],
      internalDiscipline: '',
      internalMsrp: null,
      internalWheelSize: null,
      internalSize: null,
      internalBuild: null,
      internalCategory: null,

      wheelTier: '',
      buildTier: '',
      powerMeter: null,
      frameMaterial: '',
      internalEditSuccess: false,
      internalEditError: false,
      // #endregion
      idImg: {},
      externalSubmission: false,
      partnerLogoUrl: '',
      verificationIds: undefined,
    };
  }

  paramSubmissionId = this.props.match.params.submissionId;
  static contextType = cycleTimingsContext;

  componentDidMount() {
    const fetchFeatureToggle = async () => {
      try {
        const response = await axios.get(`${API_URL}/user/featureToggles/lookerModelReportBaseUrl`);
        if (response) {
          this.setState({ lookerModelReportBaseUrl: response?.data ?? undefined });
        }
      } catch (e) {
        console.error(e);
      }
      try {
        const response = await axios.get(
          `${API_URL}/user/featureToggles/lookerExpandDefaultStatusIds`,
        );
        if (response) {
          this.setState({ expandDefaultStatusIds: response?.data ?? undefined });
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchFeatureToggle();

    this.initialFetch();
    document.addEventListener('keydown', this._handleKeyPress, false);
    this.checkPermissions();
    const { setCycleTimingSubId } = this.context;
    setCycleTimingSubId(this.paramSubmissionId);
  }

  initialFetch = async () => {
    this.fetchData();
    this.genericFetchData('/tradeup/syb/declineReasons', 'declineReasons');
    this.genericFetchData('/tradeup/syb/buyers', 'buyers');
    this.genericFetchData('/tradeup/syb/submissionStatuses', 'statuses', formatStatuses);
    this.genericFetchData('/tradeup/syb/categories', 'categories');
    this.genericFetchData('/tradeup/syb/brands', 'brands');
    this.genericFetchData('/tradeup/syb/pipelines', 'pipelines');
    this.genericFetchData('/products/disciplines', 'disciplines');
  };

  syncShopifyCustomerEmail = async () => {
    if (this.state.submission) {
      const { customerNumber, id } = this.state.submission;
      if (customerNumber && id) {
        try {
          const shopifyRes = await axios.put(
            `${API_URL}/tradeup/syb/submission/${id}/customer/${customerNumber}`,
            {},
          );
          if (shopifyRes && shopifyRes.data) {
            return this.refetchSubmission();
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  // todo: break this up to be typable
  genericFetchData = async (
    endpoint: string,
    stateKey: string,
    optionalFunction?: (data: any) => void,
  ) => {
    try {
      const url = `${API_URL}${endpoint}`;
      const response: any = await axios.get(url);
      if (response && response.data && optionalFunction) {
        response.data = optionalFunction(response.data);
      }
      this.setState({ [stateKey]: response.data } as any);
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  checkPermissions = () => {
    // e.g. "[11, 14, 6]"
    const permissionsString = localStorage.getItem('userPermissions');
    if (permissionsString) {
      const permission = JSON.parse(permissionsString);
      if (permission && permission.includes(11)) {
        this.setState({ editPermissions: false });
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyPress, false);
  }

  putSubmission = async (body: any) => {
    try {
      const partnerSiteId = this.state?.submission?.partnerSiteId;
      const updateUrl = partnerSiteId
        ? `${API_URL}/tradeup/partners/${partnerSiteId}/submission`
        : `${API_URL}/tradeup/syb/submission`;
      await axios.put(updateUrl, body);
      return this.refetchSubmission();
    } catch (e) {
      console.error(e);
    }
  };

  updateItemInfo = (item: any, index: number) => {
    // todo: any
    const internalYear = item.year;
    const internalSize = item.size;
    const internalWheelSize = item.wheelSize;
    const internalBuild = item.build;
    const internalBrand = item.confirmedBrand;
    const internalModel = item.confirmedModel;
    const internalPipeline = item.pipelineId;
    const internalDiscipline = item.disciplineId;
    const internalCategory = item.categoryId;
    const internalMsrp = item.msrp;
    const powerMeter = item.powerMeter;
    const buildTier = item.buildTier;
    const wheelTier = item.wheelTier;
    const frameMaterial = item.frameMaterial;
    // todo: this method is only called after a successful submission fetch but TS can't infer that
    const submission = { ...(this.state.submission as Submission) };

    if (submission?.items) {
      submission.items[index] = { ...item };
    }

    this.setState({
      internalYear,
      internalSize,
      internalWheelSize,
      internalBuild,
      internalBrand,
      internalModel,
      internalPipeline,
      internalDiscipline,
      internalCategory,
      powerMeter,
      buildTier,
      frameMaterial,
      wheelTier,
      internalMsrp,
      submission,
    });
  };

  parseOffer = (val: string | null) => (val ? parseFloat(val) : null);

  fetchData = async () => {
    this.setState({ loading: true });
    const url = `${API_URL}/tradeup/syb/submission/${this.paramSubmissionId}`;
    try {
      const response = await axios.get(url);
      if (!response || !response?.data) {
        this.setState({ loading: false, noSubmissionFound: true });
        throw new Error(`got a response without data: ${JSON.stringify(response)}`);
      }
      const {
        submission,
        reactiveSettings,
        submissionPrevious,
        catalogProductId,
      }: FetchDataResult = response.data;
      if (submission.partnerSiteId && !this.state.partnerLogoUrl) {
        this.fetchPartnerSiteImageUrl(submission.partnerSiteId);
      }
      const partnerSubmissions = await fetchPreviousPartnerSubmissions(submission?.email);
      const allPreviousSubs = [...submissionPrevious, ...partnerSubmissions].sort(
        (a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime(), // todo: any
      );
      const submissionReceivers = await fetchSubmissionReceivers(submission.id);
      const receivedTrackingNumbers: {
        trackingNumber: string;
        linkUrl: string;
      }[] = [];
      if (submission.receivedTrackingNumbers) {
        const trackingNumbers = Array.from(
          new Set(
            submission.receivedTrackingNumbers.map(
              (received: SubmissionReceivedTrackingNumber) => received.trackingNumber,
            ),
          ),
        );
        trackingNumbers.forEach(trackingNumber =>
          receivedTrackingNumbers.push({
            trackingNumber,
            linkUrl: `https://www.google.com/search?q=${trackingNumber}`,
          }),
        );
      }

      const esp = this.parseOffer(submission?.expectedValue);

      const itemHistory = [];
      const imageHistory = [];
      for (const item of submission?.items) {
        if (item?.histories?.length) {
          itemHistory.push(...item.histories);
        }
        if (item?.images?.length) {
          for (const img of item.images) {
            if (img?.histories?.length) {
              imageHistory.push(...img.histories);
            }
          }
        }
      }

      if (submission?.isCpo && submission?.cpoSku) {
        const currentValueResponse = await services.fetchCurrentValue(submission?.cpoSku);
        if (currentValueResponse && currentValueResponse?.data) {
          this.setState({
            currentValue: {
              month: currentValueResponse.data.month,
              amount: currentValueResponse.data.amount,
              soldDate: currentValueResponse.data.sold_date,
            },
          });
        }
      }

      this.setState(
        {
          submission,
          submissionHistory: submission.histories,
          itemHistory,
          imageHistory,
          reactiveSettings,
          statusId: submission?.statusId,
          expectedValue: esp,
          failedSubmission: submission.failed,
          submissionPartnerPrevious: allPreviousSubs,
          externalSubmission: submission.externalSubmission,
          submissionReceivers,
          receivedTrackingNumbers,
          catalogProductId,
        },
        () => {
          const firstItem = submission?.items[0];
          if (firstItem) {
            this.updateItemInfo(firstItem, 0);
          }
          this.syncShopifyCustomerEmail();
        },
      );
    } catch (err) {
      this.setState({ loading: false, noSubmissionFound: true });
      console.error(err);
    }
  };

  refetchSubmission = async () => {
    const res = await axios.get(
      `${API_URL}/tradeup/syb/submission/${this.props.match.params.submissionId}`,
    );
    if (res && res?.data?.submission) {
      this.setState({ submission: res.data.submission });
    }
    return res;
  };

  handleSubmitBuyer = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as typeof e.currentTarget & {
      buyerId: { value: string };
    };
    const { submissionId } = this.props.match.params;
    try {
      await axios.put(
        `${API_URL}/tradeup/syb/submission/${submissionId}/buyer/${form.buyerId.value}`,
        {},
      );
      await this.refetchSubmission();
    } catch (e) {
      console.error(e);
    }
  };

  fetchPartnerSiteImageUrl = async (partnerSiteId: number) => {
    try {
      const response = await axios.get(`${API_URL}/tradeup/partners/${partnerSiteId}/logo`);
      if (response && response?.data) {
        this.setState({ partnerLogoUrl: response.data });
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleRejection = async (e: React.FormEvent) => {
    const { submission, rejectNote, declineReasonId, failedSubmission } = this.state;
    e.preventDefault();
    try {
      this.setState({
        editSuccess: false,
        editError: false,
      });
      await axios.post(`${API_URL}/tradeup/sendRejection`, {
        submissionId: submission?.id,
        note: rejectNote,
        declineReasonId,
        imageUrl1: failedSubmission?.imageUrl1,
        imageUrl2: failedSubmission?.imageUrl2,
      });
      this.setState({ editSuccess: true });
    } catch (error) {
      this.setState({
        editError: true,
      });
    }
  };

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleKeyPress, false);
  }

  displayModal = (idx: number, itemIdx = 0) => {
    this.setState({
      displayModal: true,
      modalImage: this.state.submission?.items?.[itemIdx]?.images?.[idx]?.url,
    });
  };

  closeModal = () => {
    this.setState({
      displayModal: false,
      displayUpdateModal: false,
      modalImage: '',
      internalEditError: false,
      internalEditSuccess: false,
      editError: false,
      editSuccess: false,
    });
  };

  // todo: make typable
  displayUpdateModal = (modal: keyof SubmissionDetailViewState) => {
    this.setState({
      [modal]: !this.state[modal],
      internalEditError: false,
      internalEditSuccess: false,
      editError: false,
      editSuccess: false,
    } as any);
  };

  // todo: deprecate in favor of ExpandRotateImage
  rotate = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ rotate: this.state.rotate + 90 });
  };

  // todo: this should all be moved into some slideshow that wraps ExpandRotateImage
  _handleKeyPress = (e: React.KeyboardEvent | KeyboardEvent) => {
    const { displayModal, submission, rotate } = this.state;

    if (e.key === 'Escape') {
      this.closeModal();
    }

    if (displayModal) {
      const images = submission?.items[0]?.images || [];
      const totalImages = images.length;

      if (totalImages > 0) {
        let currentIndex = images.findIndex(
          (img: { url: string }) => img.url === this.state.modalImage,
        );

        if (e.key === 'ArrowRight') {
          // Right arrow key
          currentIndex = Math.min(currentIndex + 1, totalImages - 1);
        } else if (e.key === 'ArrowLeft') {
          // Left arrow key
          currentIndex = Math.max(currentIndex - 1, 0);
        }

        const newImage = images[currentIndex];

        if (newImage) {
          this.setState({
            modalImage: newImage.url,
          });
        }
      }

      if (e.key === 'd') {
        // D key for rotation clockwise
        this.setState({ rotate: rotate + 90 });
      }

      if (e.key === 'a') {
        // A key for rotation counterclockwise
        this.setState({ rotate: rotate - 90 });
      }
    }
  };

  getInternalInfoLabel = (value?: string | null) => {
    if (!value) {
      return 'Internal Info';
    }
    return `Internal ${value.charAt(0).toUpperCase() + value.substr(1)} Info`;
  };

  // #endregion
  render() {
    const {
      submission,
      disciplines,
      submissionHistory,
      itemHistory,
      imageHistory,
      buyers,
      statusId,
      currentValue,
      catalogProductId,
      submissionReceivers,
      noSubmissionFound,
    } = this.state;

    const {
      items,
      id,
      firstName,
      lastName,
      email,
      phone,
      address,
      status,
      city,
      state,
      zip,
      insuranceCost,
    } = submission || {};

    if (!submission) {
      return noSubmissionFound ? (
        <PageWrapper style={{ overflow: 'hidden' }} className="syb-detail-wrap">
          <Box
            style={{
              maxWidth: '1500px',
              paddingInline: '32px',
              marginInline: 'auto',
              marginBottom: '16px',
            }}
          >
            <p>Well this is awkward unable to find Submission: {this.paramSubmissionId}...</p>
          </Box>
        </PageWrapper>
      ) : (
        <PageWrapper style={{ overflow: 'hidden' }} className="syb-detail-wrap">
          <Loader loading={true} />
        </PageWrapper>
      );
    }

    let autoRejectedMessage: string;
    let autoRejectedTitle: string;
    if (
      [SubmissionStatusIds.INITIATED, SubmissionStatusIds.RESUBMITTED].includes(submission.statusId)
    ) {
      autoRejectedTitle = 'Auto Rejected - Awaiting Notification';
      autoRejectedMessage =
        'Customer email will be sent, and status updated, once the automated process completes.';
    } else if (submission.statusId === SubmissionStatusIds.DECLINED_BUYER) {
      autoRejectedTitle = 'Auto Rejected - Complete';
      autoRejectedMessage =
        'Submission automatically rejected and the customer has been notified via email.';
    } else if (submission?.autoRejection?.customerNotification) {
      autoRejectedTitle = 'Auto Rejected - Manually Processed - Post Decline';
      autoRejectedMessage = 'The status was changed manually after the customer was notified.';
    } else {
      autoRejectedTitle = 'Auto Rejected - Manually Processed';
      autoRejectedMessage = 'The status was changed manually before the customer was notified.';
    }

    const disciplineLabel = submission.items?.[0]?.disciplineId
      ? disciplines.find(d => d.id === submission.items[0].disciplineId)?.discipline
      : null;

    const showBikeInfo = ['bike', 'frame'].includes(submission.type) && items?.[0];
    const acceptedOffer = getAcceptedOffer(submission.activeOfferGroup?.offers || undefined);

    return (
      <PageWrapper style={{ overflow: 'hidden' }} className="syb-detail-wrap">
        <Box
          style={{
            maxWidth: '1500px',
            paddingInline: '32px',
            marginInline: 'auto',
            marginBottom: '16px',
          }}
        >
          <Link to={TRADEUP_SEARCH_ROUTE}>Tradeups</Link>
        </Box>
        <StickyHeader
          title={`Submission: #${id}`}
          subtitle={`Buyer: ${submission?.buyer?.name ?? 'N/A'}`}
          actions={
            <SubmissionChip label={status || ''} color={styleStatus(status)} variant="leftBorder" />
          }
          description={
            <Typography>
              {acceptedOffer && (
                <Typography>
                  {`${offerTypesConfig[acceptedOffer.offerTypeName].label} for $${
                    acceptedOffer.amountUsd
                  }`}
                </Typography>
              )}
              {`Seller: ${submission.firstName} ${submission.lastName}`}
            </Typography>
          }
          icon={
            submission?.type === 'bike' ? (
              <Bike />
            ) : submission?.type === 'frame' ? (
              <Frame />
            ) : (
              <Wheel />
            )
          }
          iconColor={
            submission?.type === 'frame'
              ? purple[500]
              : submission?.type === 'gear'
              ? cyan[600]
              : undefined
          }
          maxWidth={1500}
        />
        <Box
          style={{
            maxWidth: '1500px',
            paddingInline: '32px',
            marginInline: 'auto',
            overflow: 'hidden',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <section>
              {!submission?.buyer && (
                <form onSubmit={e => this.handleSubmitBuyer(e)}>
                  <select className="text-input" name="buyerId">
                    <option value="">Select a buyer</option>
                    {buyers.map(b => (
                      <option key={b.id} value={b.id} selected={b.email === this.props.user.email}>
                        {b.name}
                      </option>
                    ))}
                  </select>
                  <button type="submit" className="submit-button">
                    CLAIM
                  </button>
                </form>
              )}
              {!!insuranceCost && (
                <h4 className="light-attention">Insured submission for ${insuranceCost}</h4>
              )}
              <Box>
                {submission?.isCpo && submission?.cpoSku ? (
                  <Fragment>
                    <Box style={{ display: 'flex' }}>
                      <img
                        src={submission?.isCpo ? cpoShield : ''}
                        alt="cpo shield"
                        style={{
                          margin: '0 10px 0 0',
                          display: 'inline-block',
                          width: '45px',
                          verticalAlign: 'middle',
                        }}
                      />
                      <h3>
                        {`Product: `}
                        <a
                          href={`/products/360View/${submission?.cpoSku}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {submission?.cpoSku}
                        </a>
                      </h3>
                    </Box>
                    <Box>
                      <p>Guaranteed Buy Back Value: ${FormatMoney(currentValue.amount)}</p>
                      <p>Month: {currentValue.month}</p>
                      <p>
                        Sold Date:{' '}
                        {currentValue.soldDate
                          ? new Date(currentValue.soldDate).toLocaleDateString()
                          : 'N/A'}
                      </p>
                    </Box>
                  </Fragment>
                ) : null}
              </Box>
            </section>
            {!!submission.autoRejected && (
              <Alert severity="warning">
                <AlertTitle>{autoRejectedTitle}</AlertTitle>
                {autoRejectedMessage}
              </Alert>
            )}
            {this.state.partnerLogoUrl && (
              <img
                style={{ width: '300px', height: 'auto' }}
                alt="Partner TradeUP logo"
                src={this.state.partnerLogoUrl}
              />
            )}
          </Box>
          {this.state.submissionPartnerPrevious.length >= 1 && (
            <BoxWithLabel legend="Previous Submissions">
              <PreviousSubmissionsTable
                previousSubmissions={this.state.submissionPartnerPrevious}
              />
            </BoxWithLabel>
          )}
          {showBikeInfo && (
            <Fragment>
              <BoxWithLabel legend="Submission Info">
                <BikeInfo
                  year={items?.[0].yearByCustomer ?? ''}
                  make={items?.[0].brand}
                  model={items?.[0].model}
                  condition={items?.[0].condition ?? ''}
                  discipline={items?.[0].disciplineByCustomer}
                  serialNumber={submission?.serialNumber || ''}
                  imageGallery={items?.[0].images ?? []}
                  description={items?.[0].description ?? ''}
                  displayModal={this.displayModal}
                />
              </BoxWithLabel>
              {items?.[0] && (
                <BoxWithLabel
                  legend={
                    !submission.type
                      ? 'Internal Info'
                      : `Internal ${submission.type.charAt(0).toUpperCase() +
                          submission.type.substr(1)} Info`
                  }
                >
                  <InternalInfoForm
                    item={items?.[0]}
                    submissionId={submission.id}
                    submissionType={submission.type}
                    catalogProductId={catalogProductId}
                    brands={this.state.brands}
                    pipelines={this.state.pipelines}
                    disciplines={this.state.disciplines}
                    categories={this.state.categories}
                    callback={this.refetchSubmission}
                  />
                </BoxWithLabel>
              )}
            </Fragment>
          )}
          {submission?.type === 'gear' && (
            <BoxWithLabel legend="Gear Info">
              {!!catalogProductId && (
                <>
                  <Typography component="label">{`Item Catalog Product: `}</Typography>
                  <Typography component="span">
                    <Link to={`/catalog/products/${catalogProductId}`} target="_blank">
                      {catalogProductId}
                    </Link>
                  </Typography>
                </>
              )}
              {(items ?? []).map((item, index) => (
                <GearInfo
                  key={item.id}
                  itemIndex={index}
                  make={item.brand ?? ''}
                  msrp={item.msrp}
                  model={item.model}
                  imageGallery={item.images ?? []}
                  displayModal={this.displayModal}
                  type={item.description}
                />
              ))}
            </BoxWithLabel>
          )}
          {/* Area for Looker Model Info Report */}
          {!!this.state.lookerModelReportBaseUrl && (
            <BoxWithLabel legend="Model Sales Info">
              {items?.[0] && (
                <LookerModelInfo
                  baseReportUrl={this.state.lookerModelReportBaseUrl}
                  brand={submission.type === 'gear' ? items?.[0].brand : items?.[0].confirmedBrand}
                  model={submission.type === 'gear' ? items?.[0].model : items?.[0].confirmedModel}
                  submissionType={submission.type}
                  statusId={submission.statusId}
                  expandDefaultStatusIds={this.state.expandDefaultStatusIds}
                />
              )}
            </BoxWithLabel>
          )}

          {this.state.reactiveSettings && (
            <BoxWithLabel legend="Offer & Buyer Info">
              <OfferBuyerInfo
                submission={submission}
                disciplineLabel={disciplineLabel}
                // lookups
                buyers={this.state.buyers}
                statuses={this.state.statuses}
                declineReasons={this.state.declineReasons}
                reactiveSettings={this.state.reactiveSettings}
                // handlers
                putSubmission={this.putSubmission}
                triggerRefetch={this.fetchData}
                setLoading={(loading: boolean) => this.setState({ loading })}
              />
            </BoxWithLabel>
          )}
          {submission.sellerId && (
            <BoxWithLabel legend="Seller Info">
              <SellerInfoForm
                submissionId={submission.id}
                sellerId={submission.sellerId}
                partnerSiteId={submission.partnerSiteId}
                callback={this.refetchSubmission}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                address={address}
                city={city}
                state={state}
                zip={zip}
                paypalEmail={acceptedOffer?.customerPaymentDetails?.paypal_email}
                cashBasedOffer={
                  !!CASH_BASED_OFFER_TYPES.includes(
                    acceptedOffer?.offerTypeName as SubmissionOfferTypes,
                  )
                }
              />
            </BoxWithLabel>
          )}
          <BoxWithLabel legend="Legal Info">
            <LabelValuePairDisplay
              labelValuePairs={[
                {
                  label: 'Terms of service',
                  value: submission?.tos ? (
                    <Typography component="span" sx={{ backgroundColor: 'rgba(0, 193, 96, 0.2)' }}>
                      Verified
                    </Typography>
                  ) : (
                    'Unverified'
                  ),
                },
              ]}
              gridSize={{ label: 3, value: 9 }}
            />
            {submission.submissionVerifications && submission.submissionVerifications?.length > 0 && (
              <StripedDataGrid
                sx={{ mt: 2 }}
                rows={submission.submissionVerifications}
                columns={[
                  {
                    headerName: 'Veriff Session Id',
                    field: 'verificationSessionId',
                    flex: 5,
                    renderCell: (row: any) => (
                      <a
                        href={`${VERIFF_STATION_VERIFICATIONS_URL}/${row.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.value}
                      </a>
                    ),
                  },
                  {
                    headerName: 'Status When Verified',
                    field: 'submissionStatusIdWhenVerified',
                    flex: 1,
                    renderCell: (row: any) =>
                      SubmissionStatuses[
                        SubmissionStatusIds[row.value] as keyof typeof SubmissionStatuses
                      ],
                  },
                  {
                    headerName: 'Verification Created',
                    field: 'created',
                    flex: 2,
                    renderCell: (row: any) =>
                      new Date(row.value).toLocaleString('en-US', {
                        timeZone: 'America/Denver',
                      }),
                  },
                ]}
              />
            )}
          </BoxWithLabel>
          {this.state.failedSubmission && (
            <BoxWithLabel legend="Failed Submission Info">
              <Grid container direction="row" justifyContent="space-evenly">
                {/* TODO: Move Rejection Modal to Inline form (or at least improve look) */}
                {this.state.submission?.status === 'failed' && (
                  <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Button
                        color="success"
                        variant="contained"
                        onClick={() =>
                          this.setState({
                            displayRejectModal: !this.state.displayRejectModal,
                          })
                        }
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Grid container item xs={4} direction="column">
                  <LabelValuePairDisplay
                    labelValuePairs={[
                      {
                        label: 'Fedex Tracking',
                        value: this.state.failedSubmission?.shippingTrackingNumber,
                        url: this.state.failedSubmission?.shippingLabelUrl,
                        copy: {
                          value: this.state.failedSubmission?.shippingTrackingNumber,
                          hint: 'Copy FedEx Tracking Number',
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={8} className="image-wrap">
                  {this.state.failedSubmission?.imageUrl1 ||
                  this.state.failedSubmission?.imageUrl1 ? (
                    <Carousel showThumbs={false}>
                      <img
                        style={{ zIndex: 10000000 }}
                        alt="damage shot 1"
                        className="bike-image"
                        src={this.state.failedSubmission?.imageUrl1}
                      />
                      <img
                        style={{ zIndex: 10000000 }}
                        alt="damage shot 1"
                        className="bike-image"
                        src={this.state.failedSubmission?.imageUrl2}
                      />
                    </Carousel>
                  ) : (
                    <Typography sx={{ textAlign: 'center', verticalAlign: 'center' }}>
                      No Images
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} className="image-wrap">
                  <LabelValuePairDisplay
                    labelValuePairs={[
                      {
                        label: 'Fail Notes',
                        value: this.state.failedSubmission?.notes,
                      },
                    ]}
                    gridSize={{ label: 2, value: 10 }}
                  />
                </Grid>
              </Grid>
              <Box className="flex-column">
                {/* TODO: Why is this button off on it's own - move to be with Update? */}
                {!!!this.state.failedSubmission?.shippingLabelUrl &&
                  this.state.submission?.id &&
                  this.state.statusId === SubmissionStatusIds.FAILED_SENT && (
                    <ConfirmAddressModal
                      initialAddress={address}
                      initialCity={city}
                      initialEmail={this.state.submission?.email}
                      initialFirstName={this.state.submission?.firstName}
                      initialLastName={this.state.submission?.lastName}
                      initialPhone={this.state.submission?.phone}
                      initialState={state}
                      initialZip={zip}
                      submissionId={this.state.submission?.id}
                      triggerRefetch={this.fetchData}
                    />
                  )}
              </Box>
            </BoxWithLabel>
          )}

          {submissionHistory && submissionHistory?.length && (
            <BoxWithLabel legend="Submission History">
              <SubmissionHistoryTable submissionHistory={submissionHistory} />
            </BoxWithLabel>
          )}

          {!!imageHistory.length && (
            <Accordion sx={{ mt: 2, borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Image History</AccordionSummary>
              <AccordionDetails>
                <ImageHistoryTable history={imageHistory} />
              </AccordionDetails>
            </Accordion>
          )}

          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <BoxWithLabel legend="TradeUp Location">
                {submission?.dealer ? (
                  <LabelValuePairDisplay
                    labelValuePairs={[
                      { label: 'Dealer', value: submission.dealer.name },
                      {
                        label: 'Address',
                        value: (
                          <Typography>
                            <Typography>{submission.dealer.address}</Typography>
                            <Typography>
                              {submission.dealer.city} {submission.dealer.state}
                              {submission.dealer.zip}
                            </Typography>
                          </Typography>
                        ),
                      },
                      { label: 'Phone', value: formatPhone(submission.dealer.phone) },
                      { label: 'Email', value: submission.dealer.email },
                      { label: 'Site', value: submission.dealer.url, url: submission.dealer.url },
                    ]}
                  />
                ) : (
                  <Typography variant="h6">No dealer chosen</Typography>
                )}
              </BoxWithLabel>
            </Grid>
            <Grid item xs={12} lg={6}>
              <BoxWithLabel legend="Shipping Info">
                <TrackingInfo
                  submission={submission}
                  submissionReceivers={submissionReceivers}
                  triggerRefetch={this.fetchData}
                />
              </BoxWithLabel>
            </Grid>
          </Grid>

          {!!itemHistory.length && (
            <Accordion sx={{ mt: 2, borderRadius: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Item History</AccordionSummary>
              <AccordionDetails>
                <ItemHistoryTable history={itemHistory} />
              </AccordionDetails>
            </Accordion>
          )}

          {this.state.displayRejectModal && (
            <Modal
              success={this.state.editSuccess}
              error={this.state.editError}
              closeModal={() =>
                this.setState({
                  displayRejectModal: !this.state.displayRejectModal,
                  editSuccess: false,
                  editError: false,
                })
              }
              form={
                <form onSubmit={this.handleRejection}>
                  <Carousel showThumbs={false}>
                    <img
                      style={{ zIndex: 10000000 }}
                      alt="damage shot 1"
                      className="bike-image"
                      src={this.state.failedSubmission?.imageUrl1}
                    />
                    <img
                      style={{ zIndex: 10000000 }}
                      alt="damage shot 1"
                      className="bike-image"
                      src={this.state.failedSubmission?.imageUrl2}
                    />
                  </Carousel>
                  <select
                    value={this.state.declineReasonId ?? ''}
                    className="text-input"
                    name="declineReasonId"
                    onChange={e => {
                      e.preventDefault();
                      const declineReason = this.state.declineReasons.find(
                        d => d.id === parseInt(e.target.value, 10),
                      );
                      if (declineReason) {
                        this.setState({
                          declineReasonId: declineReason.id,
                          externalNote: declineReason.reason,
                          rejectNote: declineReason.reason,
                        });
                      }
                    }}
                  >
                    <option value="">Select a message for the seller</option>
                    {this.state.declineReasons
                      .filter(r => r.submissionStatusId === statusId)
                      .sort((a, b) => a.weight - b.weight)
                      .map(d => (
                        <option key={d.id} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                  </select>
                  <textarea
                    id="reject-notes"
                    placeholder="Give a reason for why the bike is being rejected"
                    rows={5}
                    value={this.state.rejectNote}
                    onChange={e => this.setState({ rejectNote: e.target.value })}
                    style={{ width: '100%' }}
                  />
                  <input className="submit-button" type="submit" value="Send" />
                </form>
              }
            />
          )}
          {this.state.displayModal && (
            <Box role="button" tabIndex={0} onClick={this.closeModal} className="backdrop">
              <Typography component="span" className="modal-image-wrap">
                <img
                  style={{ transform: `rotate(${this.state.rotate}deg)` }}
                  alt="bike detail"
                  className="modal-image"
                  src={this.state.modalImage}
                />
                <button className="rotate-btn submit-button" onClick={this.rotate}>
                  ROTATE
                </button>
              </Typography>
            </Box>
          )}
          <Loader loading={this.state.loading} />
        </Box>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(SubmissionDetailView));
