import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  List,
  ListItem,
  Typography,
  Link,
} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface Props {
  baseReportUrl: string;
  brand?: string | null;
  model?: string | null;
  submissionType?: string | null;
  statusId: number;
  expandDefaultStatusIds: number[] | undefined;
}

const LOOKER_FIELD_PREFEX = 'include%EE%80%800%EE%80%80IN%EE%80%80';

export const LookerModelInfo: FC<Props> = (props: Props) => {
  const [defaultShowLookerReport] = useState<boolean>(
    !!props.expandDefaultStatusIds?.includes(props.statusId),
  );
  const [showLookerReport, setShowLookerReport] = useState<boolean>(
    !!props.expandDefaultStatusIds?.includes(props.statusId) && !!props.brand && !!props.model,
  );
  const [iFrameUrl, setIFrameUrl] = useState<string | null>(null);

  const targetRef = useRef();
  const size = useDimensions(targetRef);

  useEffect(() => {
    if (!!props.brand && !!props.model) {
      setShowLookerReport(defaultShowLookerReport);
    }
  }, [defaultShowLookerReport, props.brand, props.model]);

  useEffect(() => {
    if (!!props.brand && !!props.model) {
      const parameters = {
        df72: `${LOOKER_FIELD_PREFEX}${props.submissionType}`,
        df2: `${LOOKER_FIELD_PREFEX}${props.brand} ${props.model}`,
      };
      const queryString = encodeURIComponent(JSON.stringify(parameters));
      const url = `${props.baseReportUrl}?params=${queryString}`;
      setIFrameUrl(url);
    } else {
      setIFrameUrl(null);
    }
  }, [props.brand, props.model, props.submissionType, props.statusId, props.baseReportUrl]);

  return (
    <Accordion
      sx={{ width: '100%' }}
      expanded={showLookerReport}
      onChange={(_e, expanded) => {
        setShowLookerReport(expanded);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          Report Details
          {!!iFrameUrl && (
            <Link sx={{ ml: 2 }} href={iFrameUrl.replace('/embed', '')} target="_blank">
              View in a new tab
            </Link>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails ref={targetRef} sx={{ mx: 1, my: 0, px: 1, py: 0 }}>
        {!!props.brand && !!props.model ? (
          <Box sx={{ mx: 0 }}>
            {!!iFrameUrl && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: `<iframe src=${iFrameUrl} width="${size.width}" height="${size.height}"></iframe>`,
                }}
              />
            )}
          </Box>
        ) : (
          <List>
            <Typography>Unable to load a report</Typography>
            {!!!props.brand && <ListItem>Brand missing from Internal Info</ListItem>}
            {!!!props.model && <ListItem>Model missing from Internal Info</ListItem>}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default LookerModelInfo;

function useDimensions(targetRef: any) {
  const getDimensions = () => {
    const widthRatio = 0.985;
    const heightRatio = widthRatio * (1600 / 1380);
    return {
      width: targetRef.current ? targetRef.current.offsetWidth * widthRatio : 0,
      height: targetRef.current ? targetRef.current.offsetWidth * heightRatio : 0,
    };
  };

  const [dimensions, setDimensions] = useState(getDimensions);

  const handleResize = () => {
    setDimensions(getDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return dimensions;
}
