// todo: customize client webpack build to shim TypeORM types
// so we can stop manually recreating these types on the FE
// https://github.com/typeorm/typeorm/blob/master/extra/typeorm-model-shim.js#L16-L21

// every field except id is technically nullable per the DB schema, but many fields are "de facto" nullable,
// either because they have default values and nothing overwrites them or because every possible
// create / update statement includes a value for that field.

import { StatusName } from './lookups';
import { Buyer } from './Buyer';
import { DeclineReason } from './DeclineReason';
import { SubmissionReceivedTrackingNumber } from './SubmissionReceivedTrackingNumber';
import { SubmissionAutoRejection } from './SubmissionAutoRejection';
import { SubmissionVerification } from './SubmissionVerification';
export type OfferType = 'cash' | 'credit' | 'trade';
export type SubmissionType = 'bike' | 'frame' | 'gear';
export enum CategoryStatus {
  OVERAGE = 'overage',
  TARGET = 'target',
  SMALL_STORAGE = 'small shortage',
  LARGE_STORAGE = 'large shortage',
  HOLE = 'hole',
  UNKNOWN = 'unknown',
}

export enum BrakeType {
  NA = 'n/a',
  RIM = 'rim',
  DISC = 'disc',
}

export enum SubmissionOfferTypes {
  CASH = 'cash',
  CONSIGNMENT_V1 = 'consignment_v1',
  CONSIGNMENT_V2 = 'consignment_v2',
  BASIS_ONLY = 'basis_only',
  DELAYED_CASH_60D = 'delayed_cash_60d',
  DELAYED_CASH_45D = 'delayed_cash_45d',
  DELAYED_CASH_30D = 'delayed_cash_30d',
  CREDIT = 'credit',
  TRADE = 'trade',
  PARTNER_VOUCHER = 'partner_voucher',
}

export enum SubmissionOfferStatus {
  ACCEPTED = 'accepted',
  EVALUATED = 'evaluated',
  PROPOSED = 'proposed',
  PAYMENT_PENDING = 'payment_pending',
  PAID = 'paid',
  REVOKED = 'revoked',
}

export enum SubmissionPaymentProvider {
  PAYPAL = 'paypal',
  STORE_CREDIT = 'store_credit',
  VENMO = 'venmo',
  CHECK = 'check',
  PARTNER_VOUCHER = 'partner_voucher',
  DEALER_TRADEIN = 'dealer_tradein',
}

export const CASH_BASED_OFFER_TYPES = [
  SubmissionOfferTypes.BASIS_ONLY,
  SubmissionOfferTypes.CASH,
  SubmissionOfferTypes.DELAYED_CASH_30D,
  SubmissionOfferTypes.DELAYED_CASH_45D,
  SubmissionOfferTypes.DELAYED_CASH_60D,
];

export const OFFER_ACCEPTED_STATE = [
  SubmissionOfferStatus.ACCEPTED,
  SubmissionOfferStatus.PAYMENT_PENDING,
  SubmissionOfferStatus.PAID,
];

export interface PaymentGiftCard {
  discount_code: string | undefined;
  amount_usd: number;
}

export interface ConsignmentDetails {
  date_listed: Date;
  sale_details?: {
    date_sold: Date;
    sale_price_usd: number;
    consignor_payment_amount_usd: number;
  };
}

export interface CustomerPaymentDetails {
  payment_provider: SubmissionPaymentProvider;
  paypal_email?: string;
  venmo_account?: string;
  check_number?: string;
  partner_voucher?: { voucherSent?: Date };
  dealer?: { id: number | null; name: string | null };
  partner?: { partner_site_id: { id: number } | null; partner_voucher_sent: Date };
  gift_cards?: PaymentGiftCard[];
  consignment_details?: ConsignmentDetails;
}

export interface EstimatedPaymentRangeMultipliers {
  minimumSalePriceMultiplier: number;
  maximumSalePriceMultiplier: number;
}

export interface Offer {
  id: number;
  offerGroupId: number;
  offerTypeName: SubmissionOfferTypes;
  amountUsd: number;
  offerPaymentTermsId: number;
  availableToSeller: boolean;
  offerStatus: SubmissionOfferStatus;
  customerPaymentDetails: CustomerPaymentDetails | null;
  estimatedPaymentDate: Date;
  paymentDate: Date;
  consignorSplit: number | null;
  estimatedPaymentRangeMultipliers: EstimatedPaymentRangeMultipliers | null;
  createdByUserId: number;
  modifiedByUserId: number;
  created: Date;
  modified: Date;
}

export interface OfferGroup {
  id: number;
  offers: Offer[];
}

export interface Submission {
  id: number;
  type: SubmissionType; // de facto non-nullable
  createdAt: Date | null;
  updatedAt: Date | null;

  // status
  statusId: number; // de facto non-nullable
  status: StatusName | null;
  tos: boolean | null;
  verified: boolean | null;
  autoRejected: boolean;

  // seller info
  sellerId: number | null;
  customerNumber: string | null;
  accountToken: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string; // de facto non-nullable
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  restrictedZip: boolean | null;
  phone: string | null;
  promocode: string | null;
  clientDescription: string | null;
  clientScreenResolution: string | null;
  clientIp: string | null;

  // seller identification
  signature?: string | null;

  // item info
  serialNumber?: string | null;

  // TODO: Update to use Offers
  // We have the active offer group - which can be used to pull back the offers
  // offer
  categoryStatus: CategoryStatus | null;
  expectedValue: string | null; // todo: these shouldn't be strings
  preAmendmentReason: string | null;
  partnerVoucherOffer: number | null;
  partnerVoucherSentAt: string | null;
  brakeType: BrakeType | null;
  offerExpires: Date | null;
  autoRejection: SubmissionAutoRejection | null;
  activeOfferGroupId: number | null;
  offers?: Offer[] | null;
  //This is a better/more descriptive name - transition above offers
  activeOfferGroup?: {
    id: number;
    offers: Offer[] | null;
  };

  // DT
  buyerId: number | null;
  lastUpdatedBy: number | null;
  bikeDisciplineId: number | null;
  bikeCategoryId: number | null;
  declineReasonId: number | null | null;
  internalNote: string | null | null;
  sku: string | null;
  poNumber: number | null;
  isInternal: boolean | null;
  isTradein: boolean | null;
  externalSubmission: boolean; // de facto non-nullable
  isCpo: boolean | null;
  cpoSku: string | null;
  pipelineId: number | null;

  // shipping
  fedexTransactionObjectId: string | null;
  fedexTrackingUrlProvider: string | null;
  fedexTrackingNumber: string | null;
  fedexLabelUrl: string | null;
  fedexParcel: string | null;
  fedexStatus: string | null;
  fedexEta: string | null;
  receivedTrackingNumbers: SubmissionReceivedTrackingNumber[] | null;

  // other
  dealerId: number | null;
  insuranceCost: number | null;
  partnerSiteId: number | null;

  // todo: standardize this interface by removing these GET /syb/submission specific values
  // and either fetching them separately or modeling a particular page's submission object
  // (e.g. SubmissionDetailView, which GETs these fields via /syb/submission) as an extension of this interface
  notes: {
    id: string;
    type: string;
    entry: string;
    createdAt: string;
  }[];
  items: Item[];
  dealer?: any; // todo: Dealer
  buyer?: Buyer;
  histories: any[]; // todo: History
  failed: any; // todo: SubmissionReturn
  declineReason?: DeclineReason;
  hasOfferItems: boolean;

  // submission verifications
  submissionVerifications?: SubmissionVerification[];
}

export interface Item {
  id: number;
  submissionId: number;
  type: string | null;
  brand: string | null;
  model: string | null;
  condition: string | null;
  description: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  year: number | null;
  size: string | null;
  wheelSize: string | null;
  confirmedBrand: string | null;
  confirmedModel: string | null;
  msrp: number | null;
  disciplineId: number | null;
  wheelTier: string | null;
  buildTier: string | null;
  powerMeter: boolean | null;
  frameMaterial: string | null;
  build: string | null;
  offer: number | null;
  pipelineId: number | null;
  categoryId: number | null;
  frameMaterialByCustomer: string | null;
  yearByCustomer: string | null;
  disciplineByCustomer: string | null;
  basisSku: string | null;
  images: any[] | null;
  histories: any[] | null;
}
