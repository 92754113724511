const FormatMoney = x => {
  const amt = typeof x === 'number' ? x.toFixed(2) : x;
  return amt ? amt.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '') : '0';
};

const FormatDiscountCode = code => {
  return code
    ?.match(/.{1,4}/g)
    ?.join(' ')
    .toUpperCase();
};

const LastFourDiscountCode = code => `xxxx ${code?.slice(-4)}`;

const FormatPercentage = percent => `${(percent * 100).toFixed(2).replace('.00', '')}%`;

const FormatDate = date => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

const FormatDateString = date => {
  return new Date(date).toLocaleDateString('en-US', {
    timeZone: 'America/Denver',
  });
};

const IsEven = num => num % 2 === 0;

const formatPhone = phone => {
  if (!phone || typeof phone !== 'string') {
    return phone;
  }
  const cleanedPhone = ('' + phone).replace(/\D/g, '');
  return cleanedPhone.length == 10
    ? `(${cleanedPhone.substring(0, 3)}) ${cleanedPhone.substring(3, 6)}-${cleanedPhone.substring(
        6,
      )}`
    : phone;
};

module.exports = {
  FormatMoney,
  FormatPercentage,
  FormatDiscountCode,
  LastFourDiscountCode,
  FormatDate,
  FormatDateString,
  IsEven,
  formatPhone,
};
